import { theme } from '@provi/provi-components'
import styled from 'styled-components'
import background_login_desktop from '../assets/svg/background-desktop-login.svg'
import SanarUPLogo from '../assets/svg/sanar-up.svg'

export const partnerLogos = {
  SanarUPLogo,
}

export const PartnerImage = styled.img`
  max-width: 159px;
  width: 100%;
`

export const PipeSeparator = styled.div`
  border-left: 2px solid white;
  height: 30px;
  display: block;
  margin: 0 8px 0 5px;
`

export const ContainerLogin = styled.div`
  width: 65%;
  height: 100vh;
  background-color: ${theme.colors.blue900};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${theme.breakpoints.down('md')} {
    height: 100%;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }
`

export const Container = styled.div`
  position: relative;
  width: 50%;

  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`

export const ContainerBackground = styled.div`
  width: 35%;
  background: url(${background_login_desktop});
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 100%;
`

export const ContainerText = styled.div``

export const Title = styled.h4`
  font-size: ${({ size }) => (size ? size : 48)}px;
  color: ${({ color = 'white' }) => color};
  margin: 20px 0;
  font-family: CenturyGothicBold, sans serif;

  ${theme.breakpoints.down('md')} {
    font-size: 16px;
  }
`

export const SubTitle = styled.p`
  color: ${theme.colors.white100};
  font-size: 24px;
  line-height: 30px;
  font-family: CenturyGothic, sans serif;
  ${({ margin }) => margin && 'margin: 0'};
  ${theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 24px;
  }
`

export const Text = styled.p`
  font-family: CenturyGothicBold, sans serif;
  color: ${theme.colors.white100};
  font-size: 16px;
  line-height: 24px;
  margin: 20px 0 20px 0;

  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 18px;
  }
`

export const Footer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;

  ${theme.breakpoints.down('md')} {
    align-items: center;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${theme.colors.blue900};
  position: relative;
`

export const Form = styled.form`
  align-items: center;
  flex-direction: column;
  display: flex;
  margin-top: 34px;
  width: 100%;

  ${theme.breakpoints.down('md')} {
    width: 100%;
  }

  .MuiInput-underline:after {
    border-bottom: 1px solid #064259;
  }

  .MuiInput-underline:before {
    border-bottom: 1px solid #064259;
  }

  .MuiFormControl-root {
    width: 100%;
    .MuiInput-root {
      margin-bottom: 8px;
    }
    &:after {
      border-bottom: 2px solid #064259;
    }
  }
`

export const TextLinkExternal = styled.a`
  line-height: 2;
  text-align: left;
  color: white;
  text-decoration: underline;
  cursor: pointer;
`

export const TextTerms = styled.p`
  line-height: 15px;
  ${({ bold }) => bold && 'font-family: CenturyGothicBold, sans-serif;'}
  font-size: 9px;
  color: ${theme.colors.white100};
  margin: 0;
  text-align: center;
`

export const ContainerBack = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  top: -60px;
  left: 0;
  z-index: 1;
  cursor: pointer;
`

export const TextModal = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin: 24px 0;
  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 18px;
    margin-top: 20px;
  }
`

export const Bold = styled.span`
  font-family: CenturyGothicBold, sans serif;
  color: ${theme.colors.white100};
  font-size: 20px;
  line-height: 30px;
  margin: 0;
  margin-bottom: 16px;

  ${theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 24px;
  }
`

export const WrapperExtraData = styled.div``

export const ExtraDataTitle = styled.h5`
  font-size: 14px;
  line-height: 18px;
  font-family: CenturyGothicBold, sans serif;
  color: ${theme.colors.pink900};
  margin: 0;
  margin-bottom: 8px;
`

export const ExtraDataText = styled.p`
  font-size: 12px;
  line-height: 18px;
  margin: 0%;
  margin-left: 8px;
  color: white;
`

export const ItemCheck = styled.div`
  display: flex;
  margin: 8px 0;
  width: auto;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`

export const WrapperIcon = styled.div`
  width: 16px;
`

export const ContainerLogo = styled.div`
  display: flex;
  align-items: center;
  color: white;
`

export const ContainerLogoname = styled.div`
  margin-left: 10px;
  font-size: 30px;
  line-height: 36px;
  font-family: CenturyGothicBold, sans serif;
  display: flex;
  align-items: center;
  ${theme.breakpoints.down('md')} {
    font-size: 20px;
    line-height: 30px;
  }
`

export const TextNamePartner = styled.div`
  display: flex;
`

export const ModalTitle = styled.h2`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 0.005em;
  color: ${theme.colors.bluePrimary};

  ${theme.breakpoints.down('md')} {
    font-size: 15.84px;
    line-height: 24px;
  }
`

export const ModalText = styled.p`
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0075em;
  color: #000000;

  ${theme.breakpoints.down('md')} {
    font-size: 13.2px;
    line-height: 20px;
    letter-spacing: 0.005em;
  }
`
