import React, { useContext } from 'react'
import { Modal, LoadingProvi, theme, ButtonV3 } from '@provi/provi-components'
import { TemplateContext } from '../../components/template/context'
import { ContainerLogin, Wrapper, Container, TextModal } from './style'
import rocket_illustration from '../../assets/svg/rocket-illustration.svg'
import { URL_EXTERNAL } from '../../constants'

export const Template = (props) => {
  const { isShowLoading, isShowModal, isMobile, typeModal, setIsShowModal, modals } = useContext(TemplateContext)

  return (
    <Wrapper color={props.color}>
      {isShowLoading && <LoadingProvi isLoadingDark={props.screenIsClear ? false : true} />}
      <ContainerLogin color={props.color} isMobile={isMobile} hasHeader={props.hasHeader}>
        <Container>{props.children}</Container>
      </ContainerLogin>
      {!isMobile && (
        <img
          src={rocket_illustration}
        />
      )}
      {isShowModal && (
        <Modal
          title={modals[typeModal].title}
          onDismiss={() => setIsShowModal(false)}
          footer={
            <ButtonV3
              type="button"
              text={modals[typeModal].textButton}
              onClick={modals[typeModal].funcButton}
              textLink={{
                text: modals[typeModal].textOpen,
                color: theme.colors.blue900,
                onClick: () => {
                  window.open(URL_EXTERNAL, '__blank')
                },
              }}
            />
          }
        >
          {modals[typeModal].texts.map((item, index) => (
            <TextModal key={index}>{item}</TextModal>
          ))}
        </Modal>
      )}
    </Wrapper>
  )
}
