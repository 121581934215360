import React, { useContext } from 'react'
import { ButtonV3 } from '@provi/provi-components'

import { TemplateContext } from '../../../components/template/context'
import { Template } from '../../../components/template'
import { ContainerBack } from '../../../components/container-back'
import { SubTitle } from '../../../components/subtitle'
import { Footer } from '../../../components/footer'
import { ArrowRight } from '../../../assets/svg/arrow-right'
import { useConfirmEmailAddress } from './hooks'

function ConfirmEmailAddress({ history, location: { search: searchProps } }) {
  const { setIsShowLoading, email, EmailId, setEmail, setEmailId } = useContext(TemplateContext)
  const [onSubmit] = useConfirmEmailAddress(setIsShowLoading, EmailId, searchProps, history, setEmail, setEmailId)

  return (
    <Template>
      <ContainerBack
        onClick={() =>
          history.push({
            pathname: '/email-nao-encontrado',
            search: searchProps,
          })
        }
      >
        <ArrowRight color="white" />
      </ContainerBack>
      <div>
        <SubTitle>Esse é o email que está vinculado ao seu CPF:</SubTitle>
        <SubTitle bold>{email}</SubTitle>
        <SubTitle bold>Avance para seguir com a sua solicitação.</SubTitle>
      </div>
      <Footer>
        <ButtonV3 text="Avançar" onClick={onSubmit} />
      </Footer>
    </Template>
  )
}

export default ConfirmEmailAddress
