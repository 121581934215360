import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import { ButtonV3 } from '@provi/provi-components'
import { SubTitle, Bold } from '../style'
import { URL_EXTERNAL } from '../../constants'

export const CridNotFound = () => {
  return (
    <FormControl>
      <SubTitle>Não existe financiamento ativo vinculado ao seu CPF.</SubTitle>
      <Bold>Retorne para o site para encontrar o conteúdo perfeito para você.</Bold>
      <ButtonV3 text="Abrir Chat" onClick={() => window.open(URL_EXTERNAL, '_blank')} />
    </FormControl>
  )
}
