import React from 'react'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl'
import { Person } from '@material-ui/icons'
import { TextErrorInput, ButtonV3, showToast } from '@provi/provi-components'
import { signUp } from '../../services'
import { Form, SubTitle, Bold } from '../style'
import { history } from '../../navigation/history'
import { hasMicrosoftDomain, transformObjectIntoQueryParams } from '../../utils'

const Formik = (props) => {
  const { values, errors, handleChange, handleSubmit, isSubmitting, setIsShowModal } = props

  return (
    <Form onSubmit={handleSubmit}>
      <FormControl>
        <Bold>Não encontramos nenhum email vinculado a esse CPF.</Bold>
        <SubTitle>Comece uma solicitação agora:</SubTitle>
        <Input
          placeholder="Digite aqui o seu melhor email"
          inputProps={{ style: { color: 'white' } }}
          value={values.email}
          type="cel"
          onChange={handleChange('email')}
          startAdornment={
            <InputAdornment position="start">
              <Person style={{ color: 'white' }} />
            </InputAdornment>
          }
        />
        {errors.email && <TextErrorInput>{errors.email}</TextErrorInput>}
      </FormControl>
      <ButtonV3
        text="Avançar"
        backgroundDisabled
        disabled={!_.isEmpty(errors) || isSubmitting}
        buttonProps={{type: 'submit'}}
        textLink={{ text: 'Algo está errado?', color: 'white', onClick: () => setIsShowModal(true) }}
      />
    </Form>
  )
}

export const FormFormik = withFormik({
  mapPropsToValues: () => ({ email: '' }),
  validationSchema: () => {
    const schema = {
      email: Yup.string().required('Preencha o campo Email'),
    }
    return Yup.object().shape(schema)
  },
  handleSubmit: async ({ email }, { props }) => {
    props.setIsShowLoading(true)
    const { partner, campanha: campaign, isISA, utmSourcesCode } = props
    try {
      props.setEmail(email)
      if (hasMicrosoftDomain(email)) {
        if (props.partner) {
          const { error } = await signUp({ email, partner, campaign, isISA, OTP: true, utmSourcesCode })

          !error &&
            history.push({
              pathname: '/verificacao',
              search: transformObjectIntoQueryParams({
                parceiro: partner,
                email,
                isa: isISA,
                campanha: campaign,
                ...utmSourcesCode,
              }),
            })
        }
      } else {
        await signUp({ email, partner, campaign, isISA })

        props.setScreen('sendEmail')
      }
    } catch (err) {
      if (err.response) {
        const errorMessage = err.response.data.error.message.split(' ')[0]
        if (['Remova', 'Não'].includes(errorMessage)) {
          showToast(err.response.data.error.message || 'Ocorreu um erro inesperado')
          return false
        }
      }
      showToast()
    } finally {
      props.setIsShowLoading(false)
    }
  },
})(Formik)
