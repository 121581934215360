import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import FormControl from '@material-ui/core/FormControl'
import { TemplateContext } from '../../components/template/context'
import { ButtonV3 } from '@provi/provi-components'
import { SubTitle, Bold } from '../style'
import { useVerificationEmail } from './hooks'

export const VerificationEmail = () => {
  let { partner, campanha } = useParams()
  const { setScreen, email, setIsShowLoading, setTypeModal, typeSign, isISA } = useContext(TemplateContext)
  const [sendEmail] = useVerificationEmail(setIsShowLoading, setTypeModal, setScreen, typeSign, partner, campanha, isISA)
  return (
    <FormControl>
      <SubTitle>
        Verifique se o email está correto para reenviarmos o link: <Bold>{email}</Bold>
      </SubTitle>
      <ButtonV3
        text="Está correto"
        onClick={() => sendEmail(email, true)}
        textLink={{
          text: 'Não está correto',
          color: 'white',
          onClick: () => setScreen('haveAccount'),
        }}
      />
    </FormControl>
  )
}
